import React from "react";
import Search from "../../general-components/search/search";

const SearchPage = () => {
  return (
    <article className="search">
      <section className="paragraph">
        <Search />
      </section>
    </article>
  );
};

export default SearchPage;
