import React, { useRef, useState } from "react";
import useSearchContext from "../store/use-search-context";
import TagInput from "./inputs/tag-input";
import NestedTags from "./nested-tags";

const Filters = () => {
  const { filter, setFilter } = useSearchContext();
  const siRef = useRef();

  const statusFilterChangeHandler = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      flagArchive: e.values,
    }));
  };

  const siFilterChangeHandler = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      si: siRef.current.checked ? ["1"] : ["0"],
    }));
  };

  return (
    <div className="search-filter-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-16 filter-wrapper">
            <div className="row">
              <div className="col-16 col-md-8 col-lg-10 filter-taxonomies">
                <div className="tag-wrapper">
                  <span className="label">Schlagworte</span>
                  <span className="label help">
                    Wählen Sie alle Schlagworte aus, die in der Suche
                    berücksichtigt werden sollen.
                  </span>
                </div>
                <NestedTags />
              </div>

              <div className="col-16 col-md-8 col-lg-6 filter-status">
                {/* Filter by archive/up to date */}
                <div className="filter-group">
                  <div className="status-wrapper">
                    <span className="label">Status</span>
                    <span className="label help">
                      Wählen Sie den Status des Dokumentes aus.
                    </span>
                  </div>
                  <TagInput
                    tags={[
                      { id: "1", label: "Archiv" },
                      { id: "0", label: "Aktuell" },
                    ]}
                    multipleTagsAllowed={false}
                    onChange={statusFilterChangeHandler}
                    value={filter.flagArchive}
                  />
                </div>
                <div className="filter-group">
                  <div className="si-wrapper">
                    <label className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="switch"
                        ref={siRef}
                        defaultChecked={true}
                        onChange={siFilterChangeHandler}
                      />
                      <div className="label-wrapper">
                        <span className="label">Schnellinformationen</span>
                        <span className="label help">
                          Wählen Sie aus, ob Schnellinformationen angezeigt
                          werden sollen oder andere Dokumente.
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
