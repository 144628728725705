import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";

moment.locale("de");

/**
 * Usually the first module on an event page - event intro module with most
 * important information.
 *
 * @todo field_ticket_link
 */
const ParagraphCalendarInformation = ({ nodeContent }) => {
  const intl = useIntl();

  const sectionClassNames = classNames({
    "paragraph paragraph-calendar-information": true,
  });

  const dateFormat = intl.formatMessage({ id: "dateformat.date.short" });
  const timeFormat = intl.formatMessage({
    id: "dateformat.time.short.withcontext",
  });

  const hasEndDate = !!nodeContent.fieldDatum.endValue;
  const endsSameDay =
    moment.utc(nodeContent.fieldDatum.value).local().format(dateFormat) ===
    moment.utc(nodeContent.fieldDatum.endValue).local().format(dateFormat);
  const dateHasTimeComponent = (dateValue) =>
    moment.utc(dateValue).local().format("HH:mm") !== "00:00" &&
    moment.utc(dateValue).local().format("HH:mm") !== "23:59";
  const startHasTimeComponent = dateHasTimeComponent(
    nodeContent.fieldDatum.value
  );
  const endHasTimeComponent =
    hasEndDate && dateHasTimeComponent(nodeContent.fieldDatum.endValue);

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-14 offset-md-1">
            {nodeContent.fieldSchlagwort.length > 0 && (
              <div className="tags calendar-information-tags">
                {nodeContent.fieldSchlagwort
                  .map((item) => item.name)
                  .join(", ")}
              </div>
            )}
            <h2 className="calendar-information-title">{nodeContent.title}</h2>
            <div className="calendar-information-datetime">
              <span className="calendar-information-datetime-date calendar-information-datetime-date-start">
                {moment
                  .utc(nodeContent.fieldDatum.value)
                  .local()
                  .format(dateFormat)}
              </span>
              {!nodeContent.fieldDauerAusblenden && startHasTimeComponent && (
                <span className="calendar-information-datetime-time calendar-information-datetime-time-start">
                  &nbsp;
                  {moment
                    .utc(nodeContent.fieldDatum.value)
                    .local()
                    .format(timeFormat)}
                </span>
              )}
              {hasEndDate &&
                !nodeContent.fieldDauerAusblenden &&
                (!endsSameDay || endHasTimeComponent) && (
                  <>&nbsp;&ndash;&nbsp;</>
                )}
              {hasEndDate &&
                !endsSameDay &&
                !nodeContent.fieldDauerAusblenden && (
                  <span className="calendar-information-datetime-date calendar-information-datetime-date-end">
                    {moment
                      .utc(nodeContent.fieldDatum.endValue)
                      .local()
                      .format(dateFormat)}
                  </span>
                )}
              {!nodeContent.fieldDauerAusblenden && endHasTimeComponent && (
                <span className="calendar-information-datetime-time calendar-information-datetime-time-end">
                  {moment
                    .utc(nodeContent.fieldDatum.endValue)
                    .local()
                    .format(timeFormat)}
                </span>
              )}
            </div>
            <div className="calendar-information-location">
              <span className="calendar-information-location-address">
                {nodeContent.fieldOrt}
              </span>
            </div>
            {nodeContent.fieldText && (
              <div className="row">
                <div
                  className="col-16 col-md-10 text"
                  dangerouslySetInnerHTML={{ __html: nodeContent.fieldText }}
                />
              </div>
            )}
            {nodeContent.fieldTicketlink && (
              <div className="calendar-information-ticket-link">
                <a
                  href={nodeContent.fieldTicketlink?.uri?.path}
                  className="btn btn-secondary btn-small"
                >
                  {nodeContent.fieldTicketlink.title || <FormattedMessage id="event.ticket" />}
                </a>
              </div>
            )}
            {nodeContent.fieldPrice !== null && (
              <div className="calendar-information-price">
                {nodeContent.fieldPrice === 0 ? (
                  <FormattedMessage id="event.participation.free" />
                ) : (
                  <span>
                    <FormattedMessage id="event.participation.price" />:{" "}
                    {nodeContent.fieldPrice.toFixed(2)}€
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphCalendarInformation.propTypes = {
  content: PropTypes.object,
  nodeContent: PropTypes.shape({
    title: PropTypes.string,
    fieldDauerAusblenden: PropTypes.bool,
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldOrt: PropTypes.string,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    fieldText: PropTypes.string,
    fieldPrice: PropTypes.number,
  }),
  intl: PropTypes.object.isRequired,
};

export default ParagraphCalendarInformation;
