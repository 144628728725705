import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

const ParagraphKontakt = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-kontakt": true,
  });

  const persons = content.fieldPersonen.filter(Boolean);
  const showImages = content.fieldBilderZeigen;

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {persons.map((item, index) => (
            <div
              key={index}
              className={classNames({
                "col-16 col-md-8 offset-md-4": persons.length === 1,
                // show images
                "col-16 col-md-8 col-lg-6 offset-lg-1":
                  showImages && persons.length > 1 && index % 2 === 0,
                "col-16 col-md-8 col-lg-6 offset-lg-2":
                  showImages && persons.length > 1 && index % 2 === 1,

                // hide images
                "col-16 col-md-8 offset-md-4 col-lg-6 offset-lg-5":
                  !showImages && persons.length === 1,
                "col-16 col-md-8 col-lg-4 offset-lg-1":
                  !showImages && persons.length > 1,
              })}
            >
              <ErrorBoundary>
                <TeaserBase
                  showImage={content.fieldBilderZeigen}
                  item={item}
                  viewMode={"kontakt"}
                />
              </ErrorBoundary>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

ParagraphKontakt.propTypes = {
  content: PropTypes.shape({
    fieldBilderZeigen: PropTypes.bool,
    fieldPersonen: PropTypes.arrayOf(teaserPersonPropTypes),
  }),
};

export default ParagraphKontakt;
