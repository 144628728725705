import React, { Component } from "react";
import PropTypes from "prop-types";

import ErrorBoundary from "../../error-boundary";
import ParagraphsSwitch from "./paragraphs-switch";

class ContentBase extends Component {
  render() {
    return (
      <section className={`content-wrap ${this.props.className}`}>
        {this.props.content.list.map((item, index) => (
          <ErrorBoundary key={index}>
            {
              // eslint-disable-next-line complexity
              (() => {
                let paragraphItem = item.entity;

                if (paragraphItem?.entityBundle === "from_library") {
                  paragraphItem =
                    item.entity.fieldReusableParagraphRawField.first.entity
                      .paragraphsRawField.first.entity;
                }

                return (
                  <ParagraphsSwitch
                    entityBundle={paragraphItem?.entityBundle}
                    paragraphItem={paragraphItem}
                    nodeContent={this.props.nodeContent}
                  />
                );
              })()
            }
          </ErrorBoundary>
        ))}
      </section>
    );
  }
}

ContentBase.propTypes = {
  content: PropTypes.shape({
    list: PropTypes.arrayOf(
      PropTypes.shape({
        entity: PropTypes.shape({
          fieldReusableParagraph: PropTypes.object,
          entityBundle: PropTypes.string.isRequired,
          fieldManuelleSortierung: PropTypes.bool,
        }),
      })
    ),
  }),
  className: PropTypes.string,
  nodeContent: PropTypes.object,
};

export default ContentBase;
