import React, { Component } from "react";
import LogoButton from "../general-components/logo-button";
import classNames from "classnames";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import menuQuery from "../general-components/navigation/menu-query.graphql";
import headerConfigQuery from "./header-config.graphql";
import Search from "./../general-components/search/search";
import UserStatusBlock from "../intern/user-status-block";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
  auth: reduxStore.auth,
});

/**
 * Close on menu link click.
 */
class SimpleNavigation extends Component {
  state = {
    menuOpen: false,
    openTree: false,
  };

  toggleFullScreenMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen }, () => {
      if (this.state.menuOpen) {
        document.body.classList.add("fullscreen-menu-open");
      } else {
        document.body.classList.remove("fullscreen-menu-open");
      }
    });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      this.toggleFullScreenMenu();
    }
  };

  render() {
    const headerConfig =
      this.props.headerConfigQuery?.entityQuery?.items[0] || {};
    const headerImage =
      headerConfig?.fieldHeaderImageRawField?.first?.entity || {};

    return (
      <>
        {this.props.showSearch && <Search />}

        <div
          id="fullscreen-menu"
          className={this.state.menuOpen ? "active" : ""}
        >
          <div className="nav-wrap">
            <div className="nav-image">
              <img
                src={headerImage.fieldMediaImage?.style.urlPath}
                alt={headerImage.fieldMediaImage?.alt}
                title={headerImage.fieldMediaImage?.title}
              />
            </div>
            <nav className="main-menu-desktop">
              <div className="main-menu-desktop-container">
                {/* @todo bno: l1items? */}
                <ul className="row main-menu-desktop-l1items">
                  {!this.props.mainMenuQuery.loading &&
                    this.props.mainMenuQuery.menuByName?.links &&
                    this.props.mainMenuQuery.menuByName.links.map(
                      (item, index) => (
                        <li
                          onClick={() =>
                            item.link.url.path !== ""
                              ? this.toggleFullScreenMenu()
                              : ""
                          }
                          className={classNames({
                            "col-16 col-xl-8": true,
                            "main-menu-desktop-l1item": true,
                            "offset-xl-8": index % 2 === 2,
                          })}
                          key={index}
                        >
                          <div className="">
                            <>
                              {item.link.url.path !== "" ? (
                                <Link to={item.link.url.path}>
                                  {item.link.label}
                                </Link>
                              ) : (
                                <span
                                  onClick={() =>
                                    this.setState({ openTree: index })
                                  }
                                >
                                  {item.link.label}
                                </span>
                              )}

                              {item.subtree.length > 0 && (
                                <ul
                                  className={
                                    this.state.openTree === index
                                      ? "main-menu-desktop-l2items active"
                                      : "main-menu-desktop-l2items"
                                  }
                                >
                                  {item.subtree.map((linkItem, linkIndex) => (
                                    <li
                                      className="main-menu-desktop-l2item"
                                      onClick={() =>
                                        linkItem.link.url.path !== ""
                                          ? this.toggleFullScreenMenu()
                                          : ""
                                      }
                                      key={linkIndex}
                                    >
                                      <Link to={linkItem.link.url.path}>
                                        {linkItem.link.label}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </>
                          </div>
                        </li>
                      )
                    )}
                </ul>
                <div className="row main-menu-desktop-l1items">
                  <div className="col-16 d-flex d-sm-none">
                    {this.props.auth?.userLoginData?.access_token ? (
                      <>
                        <UserStatusBlock
                          onClick={() => this.setState({ menuOpen: false })}
                        />
                      </>
                    ) : (
                      <Link
                        to="/intern/login"
                        className="protected-item--login"
                        onClick={() => this.setState({ menuOpen: false })}
                      >
                        <FormattedMessage id="header.login" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>

        <div id="mini-header">
          {/* @todo: why mini-header-bg2? */}
          <div className="mini-header-bg2">
            <LogoButton onClick={() => this.setState({ menuOpen: false })} />

            <nav className="mini-header-nav">
              {this.props.auth?.userLoginData?.access_token ? (
                <>
                  <UserStatusBlock
                    onClick={() => this.setState({ menuOpen: false })}
                  />
                  <Link
                    to="/intern/search"
                    className="mini-header-nav-item mini-header-nav-item--search"
                    onClick={() => this.setState({ menuOpen: false })}
                  >
                    <FormattedMessage id="header.search" />
                  </Link>
                </>
              ) : (
                <Link
                  to="/intern/login"
                  className="mini-header-nav-item mini-header-nav-item--login"
                  onClick={() => this.setState({ menuOpen: false })}
                >
                  <FormattedMessage id="header.login" />
                </Link>
              )}
              <Link
                to="#"
                id="toggle-fullscreen-menu"
                tabIndex={0}
                className={
                  this.state.menuOpen
                    ? "mini-header-nav-item--burger mini-header-nav-item active"
                    : "mini-header-nav-item--burger mini-header-nav-item"
                }
                aria-label="toggle-full-screen-menu"
                onClick={() => this.toggleFullScreenMenu()}
                onKeyDown={this.handleKeyDown}
                role="button"
              >
                <FormattedMessage id="header.menu" />
              </Link>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          /*TODO: Recursive? */
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

SimpleNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: () => ({
        variables: {
          name: "MAIN",
          /*language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",*/
        },
      }),
      name: "mainMenuQuery",
    }),
    graphql(headerConfigQuery, {
      options: () => ({
        variables: {
          name: ["header"],
        },
      }),
      name: "headerConfigQuery",
    })
  )(SimpleNavigation)
);
