import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";
import { FormattedMessage } from "react-intl";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNewsTimelineList extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {    
    if (!this.props.item) {
      return null;
    }
    
    return (
      <article className="node node-teaser teaser-news teaser-news-timeline-list">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <div className="row">
          <div className="col-16 col-sm-11 col-lg-9 offset-lg-2 order-2 order-sm-1">
            <FieldTime
              timestamp={true}
              date={
                this.props.item.publishedAtRawField?.first.value
                  ? this.props.item.publishedAtRawField.first.value
                  : this.props.item.publishedAtRawField.first.publishedAtOrNow
              }
              format={"DD.MM.YYYY"}
            />
            <h3>{this.props.item.title}</h3>

            {this.props.item.fieldTeasertext && (
              <div
                className="teaser text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldTeasertext,
                }}
              />
            )}
            {/* {this.props.item.fieldSchlagwort.length > 0 &&
                this.props.item.fieldSchlagwort[0] &&
                this.props.item.fieldSchlagwort.map((item, index) => (
                  <span key={index} className="tag">
                    {item.name}
                  </span>
                ))} */}

            <Link
              onClick={() =>
                this.props.dispatch(
                  pagerFullPageContextAction(this.props.pagerFullPage)
                )
              }
              className="btn btn-primary btn-small"
              to={
                this.props.item.url
                  ? this.props.item.url.path
                  : this.props.item.path.alias
              }
            ><FormattedMessage id="read_more" /></Link>
          </div>

          <div className="col-16 col-sm-5 col-lg-3 order-1 order-sm-2">
            <Image
              data={this.props.item.fieldTeaserbild.fieldMediaImage}
              nodeTitle={this.props.item.title}
            />
          </div>
        </div>
      </article>
    );
  }
}

TeaserNewsTimelineList.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNewsTimelineList));
