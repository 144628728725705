import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import LoadingIndicator from "../../loading-indicator";
import Result from "./result";
import EndlessScroll from "./endless-scroll";
import useSearchContext from "../store/use-search-context";
import { ErrorBoundary } from "carbon-components-react";

const Results = () => {
  const { searchQuery, loading, searchResults } = useSearchContext();

  const noResults =
    searchResults?.length === 0 && searchQuery?.length > 0 && !loading;
  /**
   * Handle no results.
   */
  if (noResults)
    return (
      <div className="results no-results">
        <div className="container">
          <p>
            <FormattedMessage id="search.no_results" />
          </p>
        </div>
      </div>
    );

  return (
    <ErrorBoundary
      fallback={
        <div className="container text">
          <p>
            <FormattedMessage id="search.error" />
          </p>
        </div>
      }
    >
      <div className="results">
        {!noResults && (
          <div className="table-responsive">
            <table className="results-wrapper table">
              <thead>
                <tr className="container">
                  <th className="sort-key">
                    <FormattedMessage id="search.fields.sort_key" />
                  </th>
                  <th className="description">
                    <FormattedMessage id="search.fields.description" />
                  </th>
                  <th className="version">
                    <FormattedMessage id="search.fields.date" />
                  </th>
                  <th className="file">
                    <FormattedMessage id="search.fields.file" />
                  </th>
                </tr>
              </thead>
              {!!searchResults?.length &&
                searchResults
                  .filter((result) => Boolean(result.title))
                  .map((result) => (
                    <Result
                      key={result.id + (result.sort_key || "")}
                      content={result}
                    />
                  ))}
            </table>
          </div>
        )}
        <div className="load-more-wrapper">
          {loading && <LoadingIndicator width={40} />}
          <EndlessScroll />
        </div>
      </div>
    </ErrorBoundary>
  );
};

Results.propTypes = {
  loading: PropTypes.bool,
  query: PropTypes.string,
  results: PropTypes.array,
  resultsCount: PropTypes.number,
  hasMoreResults: PropTypes.bool,
};

export default Results;
