import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";

import Link from "../../../link/link";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import CustomArrow from "../../../../controls/custom-arrow";

const ParagraphTeaserSlider = ({ content }) => {
  const sliderSettings = {
    appendDots: dots => (<div>
      <div className="row">
        <div className="col-16">
          <ul className="slick-dots">{dots}</ul>
        </div>
      </div>
    </div>),
    adaptiveHeight: false,
    arrows: true,
    dots: true,
    nextArrow: <CustomArrow color="var(--c-white)"/>,
    prevArrow: <CustomArrow color="var(--c-white)"/>,
    dotsClass: 'container',
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-slider": true,
  });

  return (
    <section className={sectionClassNames}>
      <ErrorBoundary>
        <Slider {...sliderSettings}>
          {content.fieldTeaserSlides.map((item, index) => (
            <React.Fragment key={index}>
              <ErrorBoundary>
                <Image
                  className="background-image"
                  data={item.fieldImage.fieldMediaImage}
                />
              </ErrorBoundary>
              <div>
                <article className="container">
                  <div className="row">
                    <div className="col-16 col-md-12 offset-lg-2 position-static">

                      {item.fieldTeaserueberschrift ? (
                        <h2 data-long-text={item.fieldTeaserueberschrift.length > 100}>{item.fieldTeaserueberschrift}</h2>
                      ) : (
                        <h2 data-long-text={item.fieldInhaltLink.uri.entity.title.length > 100}>{item.fieldInhaltLink.uri.entity.title}</h2>
                      )}


                      {item.fieldInhaltLink &&
                        <div className={"link-wrapper"}>
                          {item.fieldInhaltLink.map((link, i) =>
                            <React.Fragment key={i}>
                              <Link
                                className="btn btn-secondary"
                                link={link}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      }
                    </div>
                  </div>
                </article>
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </ErrorBoundary>
    </section>
  );
};

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        fieldTeaserueberschrift: PropTypes.string.isRequired,
        fieldImage: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
        fieldInhaltLink: PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.shape({
            __typename: PropTypes.string,
            path: PropTypes.string,
            entity: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
