import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./landingpage/landingpage";
import Event from "./event/event";
import Person from "./person/person";
import PageFooter from "./footer/page-footer";
import Header from "./header/header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import WebformPage from "./intern/webform-page/webform-page";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import LoginForm from "./intern/login-form";
import { authenticationLoginAction } from "./intern/redux-authentication";
import { tokenExpired } from "./intern/util";
import UserStatusBlock from "./intern/user-status-block";
import SearchPage from "./intern/search-page/search-page";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  auth: reduxStore.auth,
  i18n: reduxStore.i18n,
});

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    // Clear login status if jwt is expired.
    if (
      this.props.auth?.userLoginData?.access_token &&
      this.props.auth?.userLoginData?.jwt.exp <
        Math.round(new Date().getTime() / 1000)
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Clear login status if jwt is expired.
    if (
      this.props.auth?.userLoginData?.access_token &&
      tokenExpired(
        this.props.auth?.userLoginData?.access_token,
        this.props.auth?.userLoginData?.jwt.exp
      )
    ) {
      this.props.dispatch(authenticationLoginAction({}));
    }

    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      window.scrollTo(0, 0);
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }
  }

  render() {
    return (
      <IntlProvider
        locale={this.props.i18n.currentLanguage}
        defaultLocale="de"
        messages={messages[this.props.i18n.currentLanguage]}
      >
        <div className="main-content">
          <ErrorBoundary>
            <Header showSearch={false} />
          </ErrorBoundary>

          <main className="main-page-content">
            <ErrorBoundary>
              <Switch
                location={this.props.location}
                key={this.props.location.pathname}
              >
                {/* Internal Pages */}
                <Route exact path="/intern/login" component={LoginForm} />
                <Route
                  exact
                  path="/intern/forgot-password"
                  component={ForgotPasswordForm}
                />
                <Route
                  exact
                  path="/intern/reset-pass/temp"
                  component={ResetPasswordWithTempPasswordForm}
                />
                <Route
                  exact
                  path="/intern/webform/:webformId/:submissionId?"
                  component={WebformPage}
                />
                <Route
                  exact
                  path="/intern/search"
                  component={SearchPage}
                />
                <Route exact path="/intern/:alias" component={Landingpage} />
                {/* Public Pages */}
                <Route
                  exact
                  path="/:language?/page/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/projekt/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/news/:alias"
                  component={Landingpage}
                />
                <Route
                  exact
                  path="/:language?/veranstaltung/:alias"
                  component={Event}
                />
                <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                />
                <Route
                  exact
                  path="/node/:nodeId/revisions/:revisionId/view"
                  component={NodeRevision}
                />
                <Route exact path="/" component={Landingpage} />
              </Switch>
            </ErrorBoundary>
          </main>

          <ErrorBoundary>
            <PageFooter />
          </ErrorBoundary>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object,
  i18n: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(App));
