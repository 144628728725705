import React, { Component } from "react";
import { Link } from "react-router-dom";
import { self } from "../config";
import PropTypes from "prop-types";

const LogoButton = ({ onClick }) => {
  return (
    <Link className="site-logo-button" to="/" onClick={onClick}>
      <img
        src={`${self}/thav-logo-combined.svg`}
        className={"logo"}
        alt={"ThAV Logo"}
      />
    </Link>
  );
};

LogoButton.propTypes = {
  onClick: PropTypes.func,
};

export default LogoButton;
