import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import searchQuery from "../search.graphql";
import { useQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const mediaRoles = useSelector((state) => state.auth.userLoginData.roles);

  const location = useLocation();
  const history = useHistory()
  const currentSearchParams = new URLSearchParams(location.search);

  const defaultQ = currentSearchParams.get("q");
  const defaultSort = currentSearchParams.get("sort");

  const [searchQueryString, setSearchQueryString] = useState(defaultQ || "");
  const [sort, setSort] = useState(defaultSort || "sort_key:desc");
  const [sortField, sortOrder] = sort.split(":");
  const [filter, setFilter] = useState({
      tags: {
        selected: JSON.parse(currentSearchParams.get("tags")) || [],
      },
      flagArchive: JSON.parse(currentSearchParams.get("flag_archive")) || [],
      si: JSON.parse(currentSearchParams.get("si")) || ["1"],
    }
  );

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.set("q", searchQueryString);
    queryParams.set("sort", sort);
    queryParams.set("tags", JSON.stringify(filter.tags.selected));
    queryParams.set("flag_archive", JSON.stringify(filter.flagArchive));
    queryParams.set("si", JSON.stringify(filter.si));
    history.replace({ search: queryParams.toString() });
  }, [sort, filter, searchQueryString]);

  const { data, loading, error, fetchMore } = useQuery(searchQuery, {
    variables: {
      searchQueryString: searchQueryString,
      offset: 0,
      tags: [...filter.tags.selected],
      flagArchive: filter.flagArchive,
      sortField,
      sortOrder,
      mediaRoles,
      si: filter.si
    },
    notifyOnNetworkStatusChange: true,
  });

  const searchResults = data?.solrSearch?.documents;
  const resultsCount = data?.solrSearch?.count || 0;

  const value = {
    searchQueryString,
    setSearchQueryString,
    searchResults,
    loading,
    error,
    fetchMore,
    resultsCount,
    filter,
    setFilter,
    sort,
    setSort,
  };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchProvider;
