import React, { useEffect, useRef } from "react";
import useSearchContext from "../store/use-search-context";

const EndlessScroll = () => {
  const { searchResults, loading, fetchMore, resultsCount } =
    useSearchContext();

  const scrollRef = useRef();

  const fetchMoreResults = () => {
    const newOffset = searchResults.length;

    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        return Object.assign({}, prev, {
          solrSearch: {
            ...prev.solrSearch,
            documents: [
              ...prev.solrSearch.documents,
              ...fetchMoreResult.solrSearch.documents,
            ],
          },
        });
      },
      variables: {
        offset: newOffset,
      },
    });
  };

  useEffect(() => {
    const triggerElement = scrollRef.current;

    // Check if the element is intersecting with the viewport.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          !loading &&
          searchResults.length < resultsCount
        ) {
          fetchMoreResults();
        }
      },
      { threshold: 0.0, rootMargin: "3000px" }
    );

    if (triggerElement) {
      observer.observe(triggerElement);
    }

    // Clean up function.
    return () => {
      if (triggerElement) {
        observer.disconnect();
      }
    };
  });

  return <div ref={scrollRef} />;
};

export default EndlessScroll;
