import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ArrowDownRight32 } from "@carbon/icons-react";
import { compose } from "recompose";
import { graphql } from "@apollo/client/react/hoc";

import LoadingIndicator from "../general-components/loading-indicator";
import FooterSitemap from "./components/footer-sitemap";
import { SocialMedia } from "./components/social-media";
import configQuery from "../general-components/config-query.graphql";
import { Menu } from "../general-components/navigation/menu";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
  reduxStore,
});

class PageFooter extends Component {
  render() {
    const footerConfig =
      this.props.footerConfig?.entityQuery?.items?.length > 0
        ? this.props.footerConfig.entityQuery.items[0]
        : null;

    if (footerConfig) {
      return (
        <footer id="pageFooter">
          <div className="container">
            {footerConfig && (
              <>
                {footerConfig.fieldFooterSitemapZeigen && (
                  <FooterSitemap
                    menu={
                      footerConfig.fieldMenue
                        ? footerConfig.fieldMenue.targetId.toUpperCase()
                        : "MAIN"
                    }
                  />
                )}
                <div className="row">
                  <SocialMedia footerConfig={footerConfig} />

                  <div className="col-16 footer">
                    <div className="row align-items-center">
                      <div className="col-16 col-md-10">
                        <Menu
                          menuName="FOOTER"
                        />
                      </div>
                      {footerConfig.fieldCopyright && (
                        <div className="col-16 col-md-6 text-center text-md-right small">
                          &copy; {new Date().getFullYear()} {footerConfig.fieldCopyright}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </footer>
      );
    }

    return <LoadingIndicator />;
  }
}

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          fieldFooterSitemapZeigen: PropTypes.bool,
          fieldCopyright: PropTypes.string.isRequired,
          fieldMenue: PropTypes.shape({
            targetId: PropTypes.string,
          }),
          fieldFacebook: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldYoutube: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldFlickr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldInstagram: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldPinterest: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldLinkedin: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTwitter: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldVimeo: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldXing: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTumblr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(configQuery, {
      name: "footerConfig",
      options: () => ({
        variables: {
          name: "footer_social_media",
        },
      }),
    })
  )(PageFooter)
);
