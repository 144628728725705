import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphStrukturierteListe = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-strukturierte-liste": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {content.fieldHeading && (
            <div className="col-16 col-md-10 offset-md-3">
              <h3>{content.fieldHeading}</h3>
            </div>
          )}
          {content.fieldRefListenelement &&
            content.fieldRefListenelement.length > 0 &&
            <div className="col-16">
              {content.fieldRefListenelement.map((item, index) => (
                <div className="row strukturierte-liste-row" key={index}>
                  <div className="col-3 offset-md-3 strukturierte-liste-label">{item.fieldLabel}</div>
                  <div className="col-13 col-md-7 strukturierte-liste-text"
                       dangerouslySetInnerHTML={{
                         __html: item.fieldText,
                       }}
                  />
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </section>
  );
};

ParagraphStrukturierteListe.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldRefListenelement: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLabel: PropTypes.string.isRequired,
        fieldText: PropTypes.string,
      })
    )
  }),
};

export default ParagraphStrukturierteListe;
