import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { restHost } from "../../../../config";

const ParagraphDownload = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-download": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="inner-wrapper">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className={classNames({
                "col-wrapper": true,
                "col-16": true,
                "col-lg-13 offset-lg-3": true,
              })}
            >
              <div className="row">
                <div
                  className="col-16 col-sm-10 text-center text-sm-left"
                >
                  {(content.fieldDatei?.fieldTitle ||
                    content.fieldDatei?.label) && (
                    <h3>
                      {content.fieldDatei?.fieldTitle ||
                        content.fieldDatei?.label}
                    </h3>
                  )}
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: content.fieldDatei.fieldBeschreibung
                        ? content.fieldDatei.fieldBeschreibung
                        : "",
                    }}
                  />
                </div>
                <div className="col-16 col-sm-6 d-flex justify-content-center justify-content-sm-end">
                  <div className="button-wrap">
                    <a
                      className="btn btn-primary"
                      href={restHost + content.fieldDatei.mediaFileUrl.path}
                      download={true}
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    fieldDatei: PropTypes.shape({
      label: PropTypes.string.isRequired,
      fieldTitle: PropTypes.string,
      fieldBeschreibung: PropTypes.string,
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphDownload;
