import React from "react";
import PropTypes from "prop-types";

import {
  LogoFacebook32,
  LogoYoutube32,
  LogoFlickr32,
  LogoInstagram32,
  LogoLinkedin32,
  LogoPinterest32,
  LogoTwitter32,
  LogoXing32,
  LogoTumblr32,
} from "@carbon/icons-react";

export const SocialMedia = (props) => {
  const hasSocialMedia =
    props.footerConfig.fieldFacebook ||
    props.footerConfig.fieldYoutube ||
    props.footerConfig.fieldFlickr ||
    props.footerConfig.fieldInstagram ||
    props.footerConfig.fieldVimeo ||
    props.footerConfig.fieldLinkedin ||
    props.footerConfig.fieldPinterest ||
    props.footerConfig.fieldTwitter ||
    props.footerConfig.fieldXing ||
    props.footerConfig.fieldTumblr;

  if (!hasSocialMedia) {
    return null;
  }
  
  return (
    <div className="col-16 col-md-15 offset-md-1 social-media">
      {props.footerConfig.fieldFacebook && (
        <a
          className="facebook"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldFacebook.uri.path}
        >
          <LogoFacebook32 />
        </a>
      )}
      {props.footerConfig.fieldYoutube && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldYoutube.uri.path}
        >
          <LogoYoutube32 />
        </a>
      )}
      {props.footerConfig.fieldFlickr && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldFlickr.uri.path}
        >
          <LogoFlickr32 />
        </a>
      )}
      {props.footerConfig.fieldInstagram && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldInstagram.uri.path}
        >
          <LogoInstagram32 />
        </a>
      )}
      {props.footerConfig.fieldVimeo && (
        <a
          className="vimeo"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldVimeo.uri.path}
        >
          <svg
            aria-hidden="true"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink={"http://www.w3.org/1999/xlink"}
            x="0px"
            y="0px"
            viewBox="0 0 96 96"
            style={{ enableBackground: "new 0 0 96 96" }}
            xmlSpace="preserve"
          >
            <g id="XMLID_1_">
              <path
                id="XMLID_5_"
                className="st0"
                d="M15.7,35.4C22.8,29,29.1,18,36.4,20.3c12.1,3.1,6.9,26.4,13.8,37.2c9.4-5.3,15.6-16.5,13-22.3
                          c-0.6-1.6,0.1-5.7-7.6-2.1c-2,0.9-1.9-2.6-1.6-3.1c7.2-7.9,11.3-11.2,16.2-11c4.1,0.7,11.4,5.3,10.1,16.5
                          C77.6,51.9,53.2,78,45.1,77c-9.3,2.1-14-27.4-21-41.2l-6.4,2.5C15,38.7,15.4,37,15.7,35.4L15.7,35.4z"
                fill="#333333"
              />
            </g>
          </svg>
        </a>
      )}
      {props.footerConfig.fieldLinkedin && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldLinkedin.uri.path}
        >
          <LogoLinkedin32 />
        </a>
      )}
      {props.footerConfig.fieldPinterest && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldPinterest.uri.path}
        >
          <LogoPinterest32 />
        </a>
      )}
      {props.footerConfig.fieldTwitter && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldTwitter.uri.path}
        >
          <LogoTwitter32 />
        </a>
      )}
      {props.footerConfig.fieldXing && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldXing.uri.path}
        >
          <LogoXing32 />
        </a>
      )}
      {props.footerConfig.fieldTumblr && (
        <a
          className="youtube"
          target="_blank"
          rel="noopener noreferrer"
          href={props.footerConfig.fieldTumblr.uri.path}
        >
          <LogoTumblr32 />
        </a>
      )}
    </div>
  );
};

SocialMedia.propTypes = {
  footerConfig: PropTypes.shape({
    fieldFacebook: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};
