import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ComponentBildSlider from "./component-bild-slider";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphBilderSlider = ({ content }) => {
  const sliderSettings = {
    adaptiveHeight: true,
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    prevArrow: <button className="slick-prev" aria-label="Previous">←</button>,
    nextArrow: <button className="slick-next" aria-label="Next">→</button>,
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-bilder-slider": true,
    [`format-${content.fieldFormat}`]: true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-wrapper": true,
              "col-16": true,
              "col-lg-10 offset-lg-3": true
            })}
          >
            <ErrorBoundary>
              <ComponentBildSlider
                bilder={content.fieldBilderWCaptionRawField}
                sliderSettings={sliderSettings}
                globalCaption={content.fieldBildunterschrift}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaptionRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }),
            }),
          }),
        })
      ),
    }),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
