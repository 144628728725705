import React from "react";
import PropTypes from "prop-types";
import { restHost } from "../../../config";
import moment from "moment";
import { FormattedMessage } from "react-intl";

const Result = ({ content }) => {
  const dateRaw = content.publication_date || content.version;
  const date = dateRaw ? moment(dateRaw * 1000)?.format("DD.MM.YYYY") : "";

  return (
    <tbody
      className="search-result"
      data-result-id={content.id}
      data-result-score={content.score}
    >
      <tr className="container">
        <td className="sort-key">{content.sort_key}</td>
        <td className="description">
          {content.flag_archive === "1" && (
            <div className="flag-archive">
              <FormattedMessage id="search.flag_archive" />
            </div>
          )}
          <div className="title">{content.title}</div>
          <div className="tags-wrapper">
            {!!content.tname.length &&
              content.tname.map((tag, i) => (
                <small className="tag" key={i}>
                  #{tag}
                </small>
              ))}
            {!!content.cost_object_name?.length &&
              content.cost_object_name?.map((item, i) => (
                <small className="tag cost-object" key={i}>
                  #{item}
                </small>
              ))}
          </div>
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: content.description,
            }}
          />
        </td>
        <td className="version">{date}</td>
        <td className="file">
          <a
            href={restHost + content.furl}
            target="_blank"
            className="file-link"
            rel="noreferrer"
            title={content.title}
            download={true}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: content.fname,
              }}
            />
          </a>
          <small className="file-size">
            {Intl.NumberFormat("de").format(content.fsize)} KB
          </small>
          {!!content.attach_fname.length && (
            <div className="attachments-wrapper">
              <h4>
                <FormattedMessage id="search.attachments" />
              </h4>
              <ul className="attachments">
                {content.attach_fname.map((name, i) => (
                  <li key={i}>
                    <a
                      href={restHost + content.attach_furl[i]}
                      target="_blank"
                      rel="noreferrer"
                      download={true}
                      className="file-link"
                    >
                      <div className="attach-wrapper">
                        {/* <span className="attach-fname">{name}</span> */}
                        <span className="attach-ftitle">
                          {content.attach_ftitle[i]}
                        </span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </td>
      </tr>
    </tbody>
  );
};

Result.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    sort_key: PropTypes.string,
    tname: PropTypes.array,
    title: PropTypes.string,
    description: PropTypes.string,
    publication_date: PropTypes.number,
    furl: PropTypes.string,
    fname: PropTypes.string,
    fsize: PropTypes.number,
    attach_fname: PropTypes.array,
    attach_furl: PropTypes.array,
    score: PropTypes.number,
    flag_archive: PropTypes.bool,
  }),
};

export default Result;
