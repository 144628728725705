import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";
import FieldTime from "../../date-time/field-time";
import { teaserNewsPropTypes } from "./teaser-news";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNewsTimeline extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    if (!this.props.item) {
      return null;
    }
    
    return (
      <article className="node node-teaser teaser-news teaser-news-timeline">
        <div className="row">
          <div className="col-16 col-sm-8">
            <EditButton
              adminApp={this.props.adminApp}
              entityId={this.props.item.id}
              destinationRoute={this.props.location.pathname}
            />
            <Image
              data={this.props.item.fieldTeaserbild.fieldMediaImage}
              nodeTitle={this.props.item.title}
            />
          </div>
          <div className="col-16 col-sm-8 d-flex flex-column justify-content-center details-wrapper">
            <FieldTime
              timestamp={true}
              date={
                this.props.item.publishedAtRawField?.first.value ||
                this.props.item.publishedAtRawField?.first.publishedAtOrNow
              }
              format={"DD. MMMM YYYY"}
            />

            <h3>{this.props.item.title}</h3>

            {this.props.item.fieldTeasertext && (
              <div
                className="teaser text"
                dangerouslySetInnerHTML={{
                  __html: this.props.item.fieldTeasertext,
                }}
              />
            )}
            <div>
              <Link
                onClick={() =>
                  this.props.dispatch(
                    pagerFullPageContextAction(this.props.pagerFullPage)
                  )
                }
                className="btn btn-primary"
                to={this.props.item.url?.path || this.props.item.path?.alias}
              >
                weiterlesen
              </Link>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

TeaserNewsTimeline.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNewsTimeline));
