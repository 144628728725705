import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";

import { trunc } from "../../../lib/shorten-text";

import EditButton from "../../../backend/edit-button";
import getEncodedIcalUrl from "../../../lib/get-encoded-ical-url";
import { Link } from "react-router-dom";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

/**
 * @todo use "FieldTime"
 */
class TeaserEvent extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    if (!this.props.item) {
      return null;
    }

    return (
      <article
        className={`node node-${this.props.item.id} node-teaser node-teaser-default teaser-veranstaltung col-16 col-md-8 col-lg-16`}
      >
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <div className="row">
          <div className="col-16 col-lg-3">
            {this.props.item.fieldBild !== null && (
              <Image
                data={this.props.item.fieldBild.fieldMediaImage}
                nodeTitle={this.props.item.title}
              />
            )}
          </div>
          <div className="col-16 col-lg-3 d-none d-lg-block">
            <div className="meta-date top-line">
              <span>
                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("dddd")}
              </span>
              {moment
                .utc(this.props.item.fieldDatum.value)
                .local()
                .format("HH:mm") !== "00:00" && (
                <span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("HH:mm")}{" "}
                  Uhr
                </span>
              )}

              {this.props.item.fieldDatum.endValue &&
                !this.props.item.fieldDauerAusblenden && (
                  <span className="duration">
                    Dauer{" "}
                    {Math.round(
                      moment
                        .duration(
                          moment
                            .utc(this.props.item.fieldDatum.endValue)
                            .local()
                            .diff(
                              moment
                                .utc(this.props.item.fieldDatum.value)
                                .local()
                            )
                        )
                        .asHours()
                    )}{" "}
                    Std.
                  </span>
                )}
            </div>
            <div className="date">
              <span>
                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("DD.MM.YYYY")}
              </span>
            </div>
            <div className="location">{this.props.item.fieldOrt}</div>
          </div>
          <div className="col-16 d-block d-lg-none mobile-header">
            <div className="row">
              <div className="col-8">
                <span>
                  {moment
                    .utc(this.props.item.fieldDatum.value)
                    .local()
                    .format("dd, DD.MM.YYYY")}
                </span>

                {moment
                  .utc(this.props.item.fieldDatum.value)
                  .local()
                  .format("HH:mm") !== "00:00" && (
                  <span>
                    {moment
                      .utc(this.props.item.fieldDatum.value)
                      .local()
                      .format("HH:mm")}{" "}
                    Uhr
                  </span>
                )}
                {this.props.item.fieldSchlagwort?.length > 0 &&
                  this.props.item.fieldSchlagwort[0] && (
                    <span>{this.props.item.fieldSchlagwort[0].name}</span>
                  )}
              </div>
              <div className="col-8 d-flex align-items-end">
                <span>{this.props.item.fieldOrt}</span>
              </div>
            </div>
          </div>
          <div className="col-16 col-lg-6">
            {this.props.item.fieldSchlagwort?.length > 0 &&
              this.props.item.fieldSchlagwort[0] && (
                <span className="top-line d-none d-lg-block">
                  {this.props.item.fieldSchlagwort[0].name}
                </span>
              )}
            <h3>{this.props.item.title}</h3>
            {this.props.item.fieldText && (
              <div
                className="text teaser-text d-inline"
                dangerouslySetInnerHTML={{
                  __html: `${trunc(this.props.item.fieldText, 150, true)}...`,
                }}
              />
            )}
          </div>
          <div className="col-16 col-lg-4">
            <div className="justify-content-lg-end d-flex align-items-lg-baseline flex-wrap buttons">
              <a
                href={getEncodedIcalUrl(
                  this.props.item.fieldDatum,
                  this.props.item.fieldOrt,
                  this.props.item.title,
                  this.props.item.fieldText
                )}
                download="event.ics"
                className="btn btn-small btn-primary"
              >
                <FormattedMessage id="event.remember" />
              </a>
              <Link
                to={
                  this.props.item.entityUrl
                    ? this.props.item.entityUrl.path
                    : this.props.item.path.alias
                }
                className="btn btn-small btn-primary"
              >
                <FormattedMessage id="event.to_event" />
              </Link>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export const teaserEventPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldOrt: PropTypes.string,
  fieldDauerAusblenden: PropTypes.bool,
  fieldText: PropTypes.string.isRequired,
  fieldBild: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldDatum: PropTypes.shape({
    value: PropTypes.string,
    endValue: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTicketlink: PropTypes.shape({
    uri: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserEvent.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserEventPropTypes,
  location: PropTypes.object.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(TeaserEvent)));
