const authenticationDefaultState = {
    userLoginData: {
      access_token: null,
      jwt: {
        exp: 0,
      },
      password_reset_required: false,
      temp_pass: false,
    },
  },
  authenticationReducer = (state = authenticationDefaultState, action) => {
    switch (action.type) {
      case "LOGIN": {
        return Object.assign({}, state, {
          userLoginData: action.userLoginData,
        });
      }
      case "PASSWORD_RESET": {
        const newState = Object.assign({}, state.userLoginData, {
          password_reset_required: !action.passwordReset,
        });
        return Object.assign({}, state, { userLoginData: newState });
      }
      case "PASSWORD_RESET_TEMP": {
        const newState = Object.assign({}, state.userLoginData, {
          temp_pass: !action.passwordResetTemp,
        });
        return Object.assign({}, state, { userLoginData: newState });
      }
      default:
        return state;
    }
  },
  authenticationLoginAction = (userLoginData) => ({
    userLoginData,
    type: "LOGIN",
  }),
  authenticationPasswordResetAction = (passwordReset) => ({
    passwordReset,
    type: "PASSWORD_RESET",
  }),
  authenticationPasswordResetTempAction = (passwordResetTemp) => ({
    passwordResetTemp,
    type: "PASSWORD_RESET_TEMP",
  });

export {
  authenticationDefaultState,
  authenticationReducer,
  authenticationLoginAction,
  authenticationPasswordResetAction,
  authenticationPasswordResetTempAction,
};
