import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";

class Header extends Component {
  render() {
    return (
      <header id="site-header">
        <div className="container">
          <div className="row">
            <SimpleNavigation showSearch={this.props.showSearch} />
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
