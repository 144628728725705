import React from "react";
import useSearchContext from "../store/use-search-context";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "react-loader-spinner";

const ResultCountAndSort = () => {
  const { resultsCount, setSort, sort, loading } = useSearchContext();
  const { formatMessage: t } = useIntl();

  const sortChangeHandler = (e) => {
    setSort(e.target.value);
  };

  const sortOptions = [
    {
      field: "search_api_relevance",
      direction: "desc",
      label: t({ id: "search.fields.relevance" }),
    },
    {
      field: "joined_date",
      direction: "asc",
      label: `${t({ id: "search.fields.date" })} (${t({
        id: "search.sort.asc",
      })})`,
    },
    {
      field: "joined_date",
      direction: "desc",
      label: `${t({ id: "search.fields.date" })} (${t({
        id: "search.sort.desc",
      })})`,
    },
    {
      field: "sort_key",
      direction: "asc",
      label: `${t({ id: "search.fields.sort_key" })} (${t({ id: "search.sort.asc" })})`,
    },
    {
      field: "sort_key",
      direction: "desc",
      label: `${t({ id: "search.fields.sort_key" })} (${t({ id: "search.sort.desc" })})`,
    },
  ];

  return (
    <div className="sort-results-wrapper">
      <div className="container">
        <div className="row">
          {/* Sort options */}
          <div className="col-8 sort-wrapper">
            <span className="label"><FormattedMessage id="search.sort" />:</span>
            <select
              className="search-sort"
              onChange={sortChangeHandler}
              defaultValue={sort || "search_api_relevance:desc"}
            >
              {sortOptions.map((option, i) => (
                <option key={i} value={`${option.field}:${option.direction}`}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Search count does not work correctly because of temporary solution for role based access */}
          <div className="col-8 result-count">
            {!!resultsCount && (
              <>
                <span className="count">{resultsCount}</span>{" "}
                <FormattedMessage id="search.results" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCountAndSort;
