import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import TeaserNumberFact from "./teaser-number-fact";
import CustomArrow from "../../../../controls/custom-arrow";


const ParagraphNumberFacts = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-number-facts": true,
    "is-slider": content.fieldZahlenUndFakten && content.fieldZahlenUndFakten.length >= 4,
  });
  const sliderSettings = {
    adaptiveHeight: false,
    arrows: true,
    nextArrow: <CustomArrow color="var(--green-dark)"/>,
    prevArrow: <CustomArrow color="var(--green-dark)"/>,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          touchMove: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {content.fieldZahlenUndFakten && content.fieldZahlenUndFakten.length < 4 && (
            <>
              {content.fieldZahlenUndFakten.map((item, index) => (
                <TeaserNumberFact content={item} key={index}/>
              ))}
            </>
          )}
          {content.fieldZahlenUndFakten && content.fieldZahlenUndFakten.length >= 4 && (
            <Slider {...sliderSettings}>
              {content.fieldZahlenUndFakten.map((item, index) => (
                <TeaserNumberFact content={item} key={index}/>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphNumberFacts.propTypes = {
  content: PropTypes.shape({
    fieldZahlenUndFakten: PropTypes.arrayOf(
      PropTypes.shape({
        fieldZahl: PropTypes.string.isRequired,
        fieldLabel: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default ParagraphNumberFacts;
