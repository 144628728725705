import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { InlineNotification } from "carbon-components-react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import queryString from "query-string";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { authenticationLoginAction } from "./redux-authentication";
import ExecutionEnvironment from "exenv";
import { tokenExpired } from "./util";
import { cache } from "../cache";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class LoginForm extends Component {
  state = {
    loading: false,
    error: "",
    success: false,
    loginQuery: queryString.parse(this.props.location.search),
  };

  translate = (key) => {
    return this.props.intl.formatMessage({
      id: key,
    });
  };

  handleSubmit = (values, actions) => {
    this.setState({
      loading: true,
      error: "",
    });

    axios
      .post(
        `/api/v1/login`,
        {
          name: values.email,
          pass: values.password,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then((response) => {
        this.setState({ success: true });

        setTimeout(() => {
          this.props.dispatch(authenticationLoginAction(response.data));
          cache.reset();
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.error,
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      })
  };

  render() {
    if (
      this.props.auth.userLoginData.logout_token &&
      ExecutionEnvironment.canUseDOM &&
      this.props.auth.userLoginData.temp_pass
    ) {
      return <Redirect to="/intern/reset-pass/temp" />;
    }

    if (
      this.props.auth.userLoginData.logout_token &&
      !tokenExpired(
        this.props.auth.userLoginData.access_token,
        this.props.auth.userLoginData.jwt.exp
      ) &&
      ExecutionEnvironment.canUseDOM
    ) {
      return (
        <Redirect
          to={
            this.state.loginQuery.destination
              ? decodeURIComponent(this.state.loginQuery.destination)
              : "/intern/intern-willkommen"
          }
        />
      );
    }
    
    const isLoggingOut = typeof window !== "undefined" && window.location.search === "?logout=true"

    return (
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "login-wrapper": true,
              "col-16": true,
              "col-md-14 offset-md-1": true,
              "col-lg-8 offset-lg-4": true,
            })}
          >
            <div className="login-wrapper-2">
              <div className="login-form-wrapper">
                <h2 className="login-headline">
                  <FormattedMessage id="login.login" />
                </h2>
                {this.state.success && !isLoggingOut && (
                  <InlineNotification
                    kind="success"
                    title={this.translate("login.msg.login-successful")}
                    subtitle={this.translate("login.msg.redirect")}
                  />
                )}
                {isLoggingOut && (
                  <InlineNotification
                    kind="success"
                    title={this.translate("login.msg.logout-successful")}
                    subtitle={this.translate("login.msg.logout-successful-subtitle")}
                  />
                )}
                {!this.state.success && this.state.error !== "" && (
                  <InlineNotification
                    kind="error"
                    title={this.translate("login.error.authentication")}
                    subtitle={this.state.error}
                  />
                )}
                <Formik
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions);
                  }}
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      //.email()
                      .required(this.translate("login.msg.userhandle-required")),
                    password: yup
                      .string()
                      .required(this.translate("login.msg.password-required")),
                  })}
                  className="form-container"
                >
                  <Form className="login-form-groups">
                    <div className="form-group login-form-group">
                      <label className="sr-only" htmlFor="email">
                        <FormattedMessage id="login.label.userhandle" />
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control login-form-control"
                        placeholder={this.translate("login.label.userhandle")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="form-error"
                      />
                    </div>
                    <div className="form-group login-form-group">
                      <label className="sr-only" htmlFor="password"></label>
                      <Field
                        type="password"
                        name="password"
                        className="form-control login-form-control"
                        placeholder={this.translate("login.label.password")}
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="form-error"
                      />
                    </div>
                    <div className="form-group login-form-group login-form-group--buttons">
                      <button
                        type="submit"
                        className="btn btn-secondary login-form-action-login"
                      >
                        <FormattedMessage id="login.action.login" />
                      </button>
                    </div>
                  </Form>
                </Formik>

              </div>
                <div className="col-16 d-flex justify-content-center">
                  <Link
                    to="/intern/forgot-password"
                    className="login-form-forgotpw"
                  >
                    <FormattedMessage id="login.action.forgot-password" />
                  </Link>
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(injectIntl(LoginForm)));
