import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { InlineNotification } from "carbon-components-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authenticationPasswordResetTempAction } from "./redux-authentication";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import ExecutionEnvironment from "exenv";
import { restHostBackend } from "../config";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class ResetPasswordWithTempPasswordForm extends Component {
  state = {
    loading: false,
    error: "",
    success: false,
    redirectToIntern: false,
  };

  translate = (key) => {
    return this.props.intl.formatMessage({
      id: key,
    });
  };

  handleSubmit = (values, actions) => {
    this.setState({
      loading: true,
      error: "",
    });

    /* todo @David removed {self}. is this importend for security? Why not Backend URL? */
    axios
      .post(
        `${restHostBackend}/user/lost-password-reset?_format=json`,
        {
          name: values.email,
          temp_pass: values.temp_pass,
          new_pass: values.new_pass,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then(() => {
        this.props.dispatch(authenticationPasswordResetTempAction(true));
        this.setState({ success: true });
        setTimeout(() => {
                    this.setState({ redirectToIntern: true });
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message,
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  render() {
    if (
    !this.props.auth.userLoginData.logout_token &&
    ExecutionEnvironment.canUseDOM
    ) {
    return <Redirect to="/intern/login" />;
    }

    if (this.state.redirectToIntern) {
    return <Redirect to="/intern/intern-willkommen" />;
    }

    return (
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "login-wrapper login-wrapper--changepw": true,
              "col-16": true,
              "col-md-14 offset-md-1": true,
              "col-lg-8 offset-lg-4": true,
            })}
          >
            <div className="login-wrapper-2">
              <div className="login-form-wrapper">
                <h2 className="login-headline">
                  <FormattedMessage id="login.changepw" />
                </h2>
                {this.state.success && (
                  <InlineNotification
                    kind="success"
                    title={this.translate("login.msg.success")}
                    subtitle={this.translate("login.msg.changepw.pwchanged")}
                  />
                )}
                {!this.state.success && this.state.error !== "" && (
                  <InlineNotification
                    kind="error"
                    title={this.translate("login.error.authentication")}
                    subtitle={this.state.error}
                  />
                )}
                <Formik
                  onSubmit={(values, actions) => {
                    this.handleSubmit(values, actions);
                  }}
                  initialValues={{
                    email: this.props.auth.userLoginData.current_user
                      ? this.props.auth.userLoginData.current_user.name
                      : "",
                    temp_pass: "",
                    new_pass: "",
                  }}
                  validationSchema={yup.object().shape({
                    email: yup
                      .string()
                      //.email()
                      .required("login.msg.userhandle-required"),
                    temp_pass: yup
                      .string()
                      .required(this.translate("login.msg.temp_pass-required")),
                    new_pass: yup
                      .string()
                      .required(this.translate("login.msg.new_pass-required")),
                  })}
                  className="form-container"
                >
                  <Form className="login-form-groups">
                    <div className="form-group login-form-group">
                      <label className="sr-only" htmlFor="email">
                        <FormattedMessage id="login.label.userhandle" />
                      </label>
                      <Field
                        type="text"
                        name="email"
                        className="form-control login-form-control"
                        placeholder={this.translate("login.label.userhandle")}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="form-error"
                      />
                    </div>
                    <div className="form-group login-form-group">
                      <label className="sr-only" htmlFor="temp_pass">
                        <FormattedMessage id="login.label.temppassword" />
                      </label>
                      <Field
                        type="password"
                        name="temp_pass"
                        className="form-control login-form-control"
                        placeholder={this.translate("login.label.temppassword")}
                      />
                      <ErrorMessage
                        name="temp_pass"
                        component="div"
                        className="form-error"
                      />
                    </div>
                    <div className="form-group login-form-group">
                      <label className="sr-only" htmlFor="new_pass">
                        <FormattedMessage id="login.label.newpassword" />
                      </label>
                      <Field
                        type="password"
                        name="new_pass"
                        className="form-control login-form-control"
                        placeholder={this.translate("login.label.newpassword")}
                      />
                      <ErrorMessage
                        name="new_pass"
                        component="div"
                        className="form-error"
                      />
                    </div>
                    <div className="form-group login-form-group login-form-group--buttons">
                      <button
                        type="submit"
                        className="btn btn-secondary login-form-action-login"
                      >
                        <FormattedMessage id="login.action.change-password" />
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordWithTempPasswordForm.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(
  withRouter(injectIntl(ResetPasswordWithTempPasswordForm))
);
