import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";

import { useQuery } from "@apollo/client";

/*
 * @todo: Pager.
 */
const ParagraphTimeline = ({ content }) => {
  const reduxStore = useSelector((reduxStore) => ({
    timelineConfig: reduxStore.timeLine.config,
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));

  const {
    data: nodes,
    loading,
    fetchMore,
  } = useQuery(
    teaserNodeQueryFilterTag,
    (() => {
      const tagConfig = reduxStore.timelineConfig.filter(
        (item) => item.timeLineId === content.id
      );
      const tagFiltered = tagConfig.length === 1 && tagConfig[0].config.id;

      let tags = [];

      // TODO: Not tag multi select, just one tag at a time.
      if (tagFiltered) {
        tags = [tagConfig[0].config.id];
      }

      if (content.fieldSchlagwort.length > 0) {
        for (const tag of content.fieldSchlagwort) {
          tags = [...tags, parseInt(tag.id, 10)];
        }
      }

      return {
        variables: {
          limit:
            content.fieldAnzahlDerAnzuzeigendenI === 0
              ? 1000
              : content.fieldAnzahlDerAnzuzeigendenI,
          type: content.fieldTypenTimeline,
          offset: 0,
          status: ["1"],
          tag: tags,
          filterTagEnabled: tags.length > 0,
          language: reduxStore.currentLanguage.toUpperCase(),
        },
      };
    })()
  );

  const loadMoreNodes = () =>
    fetchMore({
      variables: {
        offset: nodes.entityQuery.items.length,
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return previousResult;
        }

        fetchMoreResult.entityQuery.items = [
          ...previousResult.entityQuery.items,
          ...fetchMoreResult.entityQuery.items,
        ];

        return fetchMoreResult;
      },
    });

  const sectionClassNames = classNames({
    "paragraph paragraph-timeline": true,
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  let items = nodes.entityQuery?.items || [];
  let loadMore =
    nodes.entityQuery?.items.length > 0 &&
    nodes.entityQuery?.items.length < nodes.entityQuery?.total;

  if (items.length === 0) {
    return;
  }

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-16": true,
            })}
          >
            <div className="row">
              <div className="col-16">
                {items.map((item, index) => (
                  <TeaserBase item={item} key={index} viewMode={content.fieldView} />
                ))}
              </div>
            </div>
          </div>
        </div>

        {content.fieldZeigeMehrLaden && loadMore && (
          <div className="row">
            <div className="col-16 d-flex justify-content-end justify-content-md-center">
              <div className="btn btn-primary" onClick={loadMoreNodes}>
                <span>
                  <FormattedMessage id="load_more" />
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldFilterwolke: PropTypes.bool,
    fieldListenumschalter: PropTypes.bool,
    fieldZeigeMehrLaden: PropTypes.bool,
    fieldTypenTimeline: PropTypes.arrayOf(
      PropTypes.oneOf(["news", "blogbeitrag"])
    ),
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    fieldView: PropTypes.oneOf(["timeline", "timeline-list"])
  }),
};

export default ParagraphTimeline;
