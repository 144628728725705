import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import taxonomyQuery from "./taxonomy-query.graphql";
import TagInput from "./inputs/tag-input";
import useSearchContext from "../store/use-search-context";

const NestedTags = () => {
  const { filter, setFilter } = useSearchContext();

  const [layer1Tags, setLayer1Tags] = useState(filter.tags.selected[0] ? [filter.tags.selected[0]] : []);
  const [layer2Tags, setLayer2Tags] = useState(filter.tags.selected[1] ? [filter.tags.selected[1]] : []);

  const layer1TagsHandler = (e) => {
    setLayer1Tags(e.values);
    setFilter((prevFilter) => ({
      ...prevFilter,
      tags: {
        selected: e.values,
      },
    }));
  };

  const layer2TagsHandler = (e) => {
    setLayer2Tags(e.values);
    setFilter((prevFilter) => ({
      ...prevFilter,
      tags: {
        selected: [...layer1Tags, ...e.values],
      },
    }));
  };

  const { data, loading } = useQuery(taxonomyQuery, {
    fetchPolicy: "network-only",
    variables: {
      type: "tags",
      filter: "type",
    },
  });
  const tags = data?.entityQuery?.items;

  if (loading) return null;

  return (
    <>
      <TagInput tags={tags} onChange={layer1TagsHandler} value={layer1Tags} />

      {tags
        ?.filter((tag) => layer1Tags.includes(tag.id))
        .map((tag) => (
          <TagInput
            key={tag.id}
            tags={tag.children}
            onChange={layer2TagsHandler}
            value={layer2Tags}
          />
        ))}
    </>
  );
};

NestedTags.propTypes = {};

export default NestedTags;
