import React from "react";
import ParagraphsSwitch from "../../paragraphs-switch";

const ParagraphRestrictedContainer = ({ content }) => {
  return content.fieldModulesRawField.list.map((module, index) => (
    <ParagraphsSwitch
      key={index}
      entityBundle={module.entity.entityBundle}
      paragraphItem={module.entity}
    />
  ));
};

export default ParagraphRestrictedContainer;
