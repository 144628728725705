import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";
import { calculateCollapseHeight } from "../../../../lib/calculate-collapse-height";
import { encodeUrl } from "../../../../lib/encode-url";

const ParagraphAkkordionText = ({ content }) => {
  const getPreExpandedItems = () => {
    let preExpandedItem = null;

    if (typeof window !== "undefined") {
      const hash = window.location.hash.replace("#", "");

      content.fieldAbschnitte.map((item) => {
        if (encodeUrl(item.fieldTitel) === hash) {
          preExpandedItem = item.id;
        }
      });
    }

    return preExpandedItem;
  };

  const [initial, setInitial] = useState(true);
  const [preExpandedItem, setPreExpandedItem] = useState(getPreExpandedItems());

  const accordion = useRef(null);

  const updateAccordion = (uuids) => {
    // Check if there are openes elements left, first element defaults to null
    if (uuids[uuids.length - 1] !== null) {
      // Get the Corresponding Element by UUID to get its Title
      content.fieldAbschnitte.map((item) => {
        // Check if UUID matches paragraphs entity id, if yes, push the title
        // to the url
        if (item.id === uuids[uuids.length - 1]) {
          // Set the URL Hash
          history.pushState(
            null,
            window.location.pathname,
            `#${encodeUrl(item.fieldTitel)}`
          );
        }
      });
    }
  };

  useEffect(() => {
    calculateCollapseHeight(accordion.current);

    if (preExpandedItem && initial) {
      // Scroll to Element
      if (typeof window !== "undefined" && window.location.hash) {
        // get Element by extracting id from hash, find matching id in element
        let openedElement = document.getElementById(
          window.location.hash.replace("#", "")
        );
        if (openedElement) {
          let scrollPosition =
            openedElement.getBoundingClientRect().top + window.scrollY - 150;

          window.scrollTo({
            top: scrollPosition,
          });
        }
      }
    }

    if (initial) {
      setInitial(false);
    }
  }, []);

  const sectionClassNames = classNames({
    "paragraph paragraph-akkordion-text": true,
  });

  return (
    <section className={sectionClassNames} ref={accordion}>
      <React.Suspense fallback={<div />}>
        <Accordion
          allowZeroExpanded={true}
          allowMultipleExpanded={true}
          preExpanded={[preExpandedItem]}
          onChange={(uuids) => updateAccordion(uuids)}
        >
          {content.fieldAbschnitte.map((item) => (
            <ErrorBoundary key={item.id}>
              <AccordionItem
                id={encodeUrl(item.fieldTitel).toString()}
                uuid={item.id}
                key={encodeUrl(item.fieldTitel)}
                data-uuid={item.id}
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="container">
                      <div className="row">
                        <div className="col justify-content-between col-16 col-lg-10 offset-lg-3">
                          <h3>{item.fieldTitel}</h3>
                          <div className="icon-wrapper">
                            <div className="icon plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="container">
                    <div className="row">
                      <div className="col-wrapper col-16 col-lg-10 offset-lg-3">
                        <div className="collapse-content">
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: item.fieldText,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </ErrorBoundary>
          ))}
        </Accordion>
      </React.Suspense>
    </section>
  );
};

ParagraphAkkordionText.propTypes = {
  content: PropTypes.shape({
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        fieldTitel: PropTypes.string,
        fieldText: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphAkkordionText;
