import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a hero-text paragraph.
 *
 * @todo In CS we have a field "fieldHintWithColouredBackgro" but we do not use
 *   it?
 */
const ParagraphHeroText = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-hero-text": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div
              className="hero-text"
              dangerouslySetInnerHTML={{
                __html: content.fieldText,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphHeroText.propTypes = {
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldText: PropTypes.string.isRequired,
    fieldHint: PropTypes.string,
  }).isRequired,
};

export default ParagraphHeroText;
