import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import ExecutionEnvironment from "exenv";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { i18nActionHelper } from "./../i18n/i18n";
import { useQuery } from "@apollo/client";
import Error404 from "../system/error-404";
import Error500 from "../system/error-500";
import { useCanonicalOrRedirect } from "../hooks/use-canonical-or-redirect";
import Error403 from "../system/error-403";
import ErrorBoundary from "../error-boundary";

const Landingpage = (props) => {
  /**
   * Hook to get information from Redux Store
   */
  const reduxStore = useSelector((reduxStore) => ({
    pagerFullPageContextId: reduxStore.appStore.pagerFullPageContextId,
    pagerConfig: reduxStore.appStore.pagerFullPages,
    microSite: reduxStore.appStore.microSite,
    microSiteFrontPage: reduxStore.appStore.microSiteFrontPage,
    auth: reduxStore.auth,
  }));

  const dispatch = useDispatch();

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(landingPageQuery, {
    variables: {
      path:
        props.location.pathname === "/" &&
        reduxStore.microSite &&
        reduxStore.microSiteFrontPage !== ""
          ? reduxStore.microSiteFrontPage
          : props.location.pathname,
    },
    onCompleted: (data) => i18nActionHelper({ data, ...props }, dispatch),
  });

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty
  if (!data?.route && !loading) {
    return <Error404 />;
  }

  if (!entity) {
    return <Error403 />;
  }

  const published = entity.status ? "node-published" : "node-unpublished";

  if (
    !reduxStore.auth?.userLoginData?.logout_token &&
    ExecutionEnvironment.canUseDOM &&
    entity.entityBundle === "interne_seite"
  ) {
    return <Redirect to="/intern/login" />;
  }

  return (
    <article
      className={`node-${entity.entityBundle} node-${entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>Thüringer Apothekerverband e.V. | {entity.label}</title>
      </Helmet>

      <ErrorBoundary>
        {entity.fieldModules && (
          <ContentBase
            content={entity.fieldModules}
            className="content-wrap-landingpage"
          />
        )}
      </ErrorBoundary>

      {reduxStore.pagerFullPageContextId &&
        props.pagerConfig.filter(
          (config) => config.id === reduxStore.pagerFullPageContextId
        ).length > 0 && (
          <PagerFullPage
            config={
              reduxStore.pagerConfig.filter(
                (config) => config.id === reduxStore.pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </article>
  );
};

Landingpage.propTypes = {
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default withRouter(Landingpage);
