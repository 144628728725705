import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link32 } from "@carbon/icons-react";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphSectionheading = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-sectionheading": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row justify-content-between">
          <div
            className={classNames({
              "col-wrapper": true,
              "col-15": true,
            })}
          >
            <Hyphenate
              id={content.fieldHeading.toLowerCase().replace(/\W/g, "-")}
              className="section-heading"
              wrapper={<h2/>}
              htmlString={true}
            >
              {content.fieldHeading}
            </Hyphenate>
          </div>
          {content.fieldSetAnchorLink && (
            <div className="col-1 sectionheading-anchor-wrap">
              <a
                className="anchor"
                href={`#${content.fieldHeading
                  .toLowerCase()
                  .replace(/\W/g, "-")}`}
              >
                <Link32 />
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphSectionheading.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
