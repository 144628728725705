import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastNotification } from "carbon-components-react";
import { Link } from "react-router-dom";
import axios from "axios";

import { authenticationLoginAction } from "./redux-authentication";
import { cache } from "../cache";
import { withRouter } from "react-router-dom";
import moment from "moment";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class UserStatusBlock extends Component {
  state = {
    error: "",
    subMenuOpen: false,
  };

  logout = () => {
    axios
      .post(
        `/api/v1/logout`,
        {
          access_token: this.props.auth.userLoginData.access_token,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then(() => {
        this.props.dispatch(authenticationLoginAction({}));
        cache.reset();
        this.props.history.push("/intern/login?logout=true")
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message,
        });
        this.props.dispatch(authenticationLoginAction({}));
      })
  };

  render() {
    if (this.state.error !== "") {
      return (
        <div className="user-status-block">
          <ToastNotification
            kind="error"
            title="Authentication Error"
            subtitle={this.state.error}
            caption={<a href="/">Neu laden</a>}
          />
        </div>
      );
    }

    if (this.props.auth.userLoginData.logout_token) {
      return (
        <div className="mini-header-nav-item mini-header-nav-item--account user-status-block">
          <div>Account</div>
          <ul
            className={`account-links ${
              this.state.subMenuOpen ? "active" : ""
            }`}
          >
            <li>
              <Link to="/intern/intern-willkommen" onClick={this.props.onClick}>
                Startseite interner Bereich
              </Link>
            </li>
            <li>
              <Link to="/intern/search" onClick={this.props.onClick}>
                Suche
              </Link>
            </li>
            <li>
              <a onClick={() => this.logout()}>Logout</a>
            </li>
            <p>
              Sie sind angemeldet als{" "}
              {this.props.auth.userLoginData.current_user.name}.<br/>
              Sie werden automatisch am{" "}
              {moment
                .unix(this.props.auth.userLoginData.jwt.exp)
                .format("DD.MM.YY HH:mm")}{" "}
              ausgeloggt.
            </p>
          </ul>
        </div>
      );
    }

    return (
      <div className="user-status-block">
        <ToastNotification
          kind="info"
          caption={<Link to="/app/login">Login</Link>}
          title="Login Status"
          subtitle={"Sie sind nicht angemeldet"}
        />
      </div>
    );
  }
}

UserStatusBlock.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withRouter(UserStatusBlock));
