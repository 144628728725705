import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { restHost } from "../../../../config";

const ParagraphZitat = ({ content }) => {
  const { fieldImage = null, fieldZitat, fieldQuelle } = content;
  const noimage = fieldImage === null;
  // const noimage = true;
  const hasimage = !noimage;

  const sectionClassNames = classNames({
    "paragraph paragraph-zitat beno": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          {hasimage && (
            <div
              className={classNames({
                "zitat-image-col": true,
                "col-14 offset-1": true,
                "col-md-5 offset-md-0": true,
                "col-lg-4 offset-lg-2": true,
                "col-xl-3 offset-xl-3": true,
              })}
            >
              <ErrorBoundary>
                <div className={"zitat-image " + fieldImage.entityBundle}>
                  {fieldImage.entityBundle === "bild" && (
                    <Image data={fieldImage.fieldMediaImage} />
                  )}
                  {fieldImage.entityBundle === "logo_partner" && (
                    <img
                      src={restHost + fieldImage.mediaFileUrl.path}
                      alt={fieldImage.fieldMediaImage.alt}
                      title={fieldImage.fieldMediaImage.title}
                    />
                  )}
                </div>
              </ErrorBoundary>
            </div>
          )}

          <div
            className={classNames({
              "zitat-text-col": true,
              noimage: noimage,
              hasimage: hasimage,
              "col-14 offset-1": true,
              "col-md-11 offset-md-0": hasimage,
              "col-lg-8": hasimage,
              "col-xl-7": hasimage,
              "col-lg-10 offset-lg-3": noimage,
            })}
          >
            <div
              className={classNames({
                "zitat-text": true,
                noimage: noimage,
                hasimage: hasimage,
              })}
            >
              <blockquote className="text">
                {fieldZitat}
                <cite>{fieldQuelle}</cite>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphZitat.propTypes = {
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.object,
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
