import React from "react";
import Results from "./components/results";
import SearchProvider from "./store/search-provider";
import SearchInput from "./components/inputs/search-input";
import Filters from "./components/filters";
import ResultCountAndSort from "./components/result-count-and-sort";

const Search = () => {
  return (
    <div className="search">
      <Filters />
      <SearchInput />
      <ResultCountAndSort />
      <Results />
    </div>
  );
};

Search.propTypes = {};

const SearchWithProvider = () => (
  <SearchProvider>
    <Search />
  </SearchProvider>
);
SearchWithProvider.displayName = "Search";
export default SearchWithProvider;
