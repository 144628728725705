import React, { useCallback, useRef } from "react";
import classNames from "classnames";
import { useDebounce } from "react-use";
import useSearchContext from "../../store/use-search-context";
import { useIntl } from "react-intl";
import { debounce } from "lodash";

const SearchInput = () => {
  const inputRef = useRef();
  const { searchQueryString, setSearchQueryString } = useSearchContext();

  const searchDebounceTime = 750;
  const intl = useIntl();

  const debounceInput = useCallback(debounce((value) =>{
    setSearchQueryString(value);
  }, searchDebounceTime), []);

  const inputChangeHandler = ({ target: { value } }) => {
    debounceInput(value)
  }

  return (
    <div className="search-input-wrapper">
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              searching: searchQueryString.length > 0,
              "search-input": true,
              "col-16": true,
            })}
          >
            <div className="overflow-wrapper">
              <input
                ref={inputRef}
                id="search-input"
                tabIndex="0"
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: "search.placeholder",
                })}
                defaultValue={searchQueryString}
                type="search"
                name="searchString"
                onChange={inputChangeHandler}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
