import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Hyphenate from "../../../hyphenate/hyphenate";

const ParagraphPageTitle = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-page-title": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container page-title">
        <div className="row page-title-row">
          <div className="col-16 col-md-14 offset-md-1">
            <h1><Hyphenate>{content.fieldHeading}</Hyphenate></h1>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphPageTitle.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
  }),
};

export default ParagraphPageTitle;
