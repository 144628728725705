import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import LoadingIndicator from "../general-components/loading-indicator";
import ContentBase from "../general-components/content-base/content-base";
import eventPageQuery from "./event.graphql";
import PagerFullPage from "../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import { i18nActionHelper } from "../i18n/i18n";
import Error500 from "../system/error-500";
import Error404 from "../system/error-404";
import { useQuery } from "@apollo/client";
import Error403 from "../system/error-403";
import { useCanonicalOrRedirect } from "../hooks/use-canonical-or-redirect";

const Event = (props) => {
  const queryStrings = props.location.search;

  /**
   * Hook to get information from Redux Store
   */
  const reduxStore = useSelector((reduxStore) => ({
    pagerConfig: reduxStore.appStore.pagerFullPages,
  }));
  const dispatch = useDispatch();

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(eventPageQuery, {
    variables: {
      path: props.location.pathname,
    },
    onCompleted: (data) => i18nActionHelper({ data, ...props }, dispatch),
  });
  
  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but the route does not exist
  if (!route && !loading) {
    return <Error404 />;
  }

  if (!entity) {
    return <Error403 />
  }

  const published = entity.status
    ? "node-published"
    : "node-unpublished";

  return (
    <article
      className={`node-${entity.entityBundle} node-${entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Thüringer Apothekerverband e.V. | ${entity.label}`}</title>
      </Helmet>

      <ContentBase
        content={entity.fieldModules}
        nodeContent={entity}
      />

      {queryStrings.p &&
        reduxStore.pagerConfig.filter((config) => config.id === queryStrings.p)
          .length > 0 && (
          <PagerFullPage
            config={
              reduxStore.pagerConfig.filter(
                (config) => config.id === queryStrings.p
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </article>
  );
};

Event.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withRouter(Event);
