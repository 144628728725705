import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import EforkHyphenated from "../../../../lib/efork-hyphenated";
import Link from "../../../link/link";
import Image from "../../../image/image";

const ParagraphTeaserImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-image": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div
          className={classNames({
            row: true,
            "flex-row-reverse": content.fieldImagePosition === "right",
          })}
        >
          <div
            className={classNames({
              "col-16 col-lg-7 teaser-image-image": true,
              "offset-lg-1 teaser-image-image":
                content.fieldImagePosition === "right",
            })}
          >
            <Image
              data={content.fieldImage.fieldMediaImage}
              nodeTitle={content.fieldHeading}
            />
          </div>
          <div
            className={classNames({
              "col-16 col-lg-8 teaser-image-text": true,
              "offset-lg-1": content.fieldImagePosition === "left",
            })}
          >
            <div>
              <EforkHyphenated>
                <h2>{content.fieldHeading}</h2>
              </EforkHyphenated>

              {content.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: content.fieldText,
                  }}
                />
              )}
              {content.fieldCallToAction && (
                <Link
                  className="btn btn-primary"
                  link={content.fieldCallToAction}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.string,
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
