import React from "react";
import PropTypes from "prop-types";
import { restHostBackend } from "../../../../config";

const TeaserNumberFact = ({ content }) => (
  <div className="col d-flex justify-content-center">
    <div className="number-fact main">
      <div className="img-wrap">
        {content.fieldIcon && (
          <img
            className="image-icon"
            alt={content.fieldLabel}
            src={content.fieldIcon.mediaFileUrl.path}
          />
        )}
      </div>
      <div className="text-wrap">
        {content.fieldZahl && (
          <div className="number">{content.fieldZahl}</div>
        )}
        {content.fieldLabel && (
          <div className="label">{content.fieldLabel}</div>
        )}
      </div>
    </div>
  </div>
);

TeaserNumberFact.propTypes = {
  content: PropTypes.shape({
    fieldZahl: PropTypes.string,
    fieldLabel: PropTypes.string,
    fieldIcon: PropTypes.shape({
      name: PropTypes.string,
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default TeaserNumberFact;
