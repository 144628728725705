import React from "react";

function CustomArrow({onClick, className, color}) {
  //@todo: color-prop
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="61"
        fill="none"
        version="1.1"
        viewBox="0 0 34 61"
        className="custom-arrow chiffron"
      >
        <path
          stroke={color}
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth="2"
          d="M2 2l29 28.5L2 59"
        />
      </svg>
    </div>

  );
}

export default CustomArrow;
