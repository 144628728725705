import React from "react";

const Error403 = () => {
  return (
    <div className="container error error-403">
      <div className="row">
        <div className="col-16">
            <h1 className="text-center">403</h1>
            <p className="text-center">Sie sind nicht berechtigt, diese Seite anzusehen.</p>
        </div>
      </div>
    </div>
  );
};

export default Error403;
