import React from 'react';
import { useIntl } from 'react-intl'
import Hyphenated from 'react-hyphen';
import de from 'hyphenated-de';

const EforkHyphenated = (props) => {
  const intl = useIntl();
  const locale = intl.locale;
  
  return (
    <>
      {locale === 'de' && (<Hyphenated language={de}>{props.children}</Hyphenated>)}
      {locale !== 'de' && (<Hyphenated>{props.children}</Hyphenated>)}
    </>
  );
};

export default EforkHyphenated;