import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A component that allows selecting tags from a list.
 * @component
 * @param {Array} tags - The list of tags to display.
 * @param {boolean} multipleTagsAllowed - Determines if multiple tags can be selected.
 * @param {function} onChange - The callback function triggered when the selected tags change.
 * @returns {JSX.Element} The rendered TagInput component.
 */
const TagInput = ({ tags, multipleTagsAllowed, onChange, value }) => {
  const [activeItems, setActiveItems] = useState(value || []);

  useEffect(() => {
    if (!onChange) return;
    // trigger onChange event whenever activeItems change
    const allTagIds = tags.map((tag) => tag.id);
    const unselectedValues = allTagIds.filter(
      (id) => !activeItems.includes(id)
    );
    onChange({
      values: activeItems,
      unselectedValues,
    });
  }, [activeItems]);

  /**
   * Resets the selected tags.
   */
  const resetItemsHandler = () => {
    setActiveItems([]);
  };

  /**
   * Handles the click event when a tag is clicked.
   * @param {string} item - The ID of the clicked tag.
   */
  const itemClickHandler = (item) => {
    setActiveItems((prevItems) => {
      let items = [...prevItems];
      if (!multipleTagsAllowed) {
        items = [];
      }

      if (items.includes(item)) {
        // remove filter value from existing list
        const index = prevItems.indexOf(item);
        items.splice(index, 1);
      } else {
        // add filter value to list
        items = [...items, item];
      }

      // trigger onChange event on this component
      return items;
    });
  };

  if (!tags || tags?.length === 0) return null;

  return (
    <ul className="tags">
      <li
        role="button"
        className={classNames({
          "tag btn btn-primary": true,
          active: activeItems.length === 0,
        })}
        onClick={resetItemsHandler}
      >
        Alle
      </li>
      {tags.map((tag) => (
        <li
          role="button"
          key={tag.id}
          className={classNames({
            "tag btn btn-primary": true,
            active: activeItems.includes(tag.id),
          })}
          onClick={() => itemClickHandler(tag.id)}
        >
          {tag.label}
        </li>
      ))}
    </ul>
  );
};

TagInput.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  multipleTagsAllowed: PropTypes.bool,
  onChange: PropTypes.func,
  /**
   * The selected tags.
   */
  value: PropTypes.arrayOf(PropTypes.string),
};

export default TagInput;
